
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.


@import "~@ng-select/ng-select/themes/default.theme.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$EHR-primary: mat-palette($mat-indigo);
$EHR-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$EHR-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$EHR-theme: mat-light-theme((
  color: (
    primary: $EHR-primary,
    accent: $EHR-accent,
    warn: $EHR-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($EHR-theme);

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "~bootstrap/dist/css/bootstrap.css";
@import '~bootstrap/dist/css/bootstrap.min.css';
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";


// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// Header themes
.header-base-light {
    @import "./assets/sass/themes/layout/header/base/light.scss";
}

.header-base-dark {
    @import "./assets/sass/themes/layout/header/base/dark.scss";
}

// Header Menu themes
.header-menu-light {
    @import "./assets/sass/themes/layout/header/menu/light.scss";
}
.header-menu-dark {
    @import "./assets/sass/themes/layout/header/menu/dark.scss";
}

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

.progress-modal {
  height: 3px;
  width: 100%;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.modal-open {
  overflow: hidden !important;
}

@media print {
  .myDivToPrint {
      background-color: white;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      padding: 15px;
      font-size: 14px;
      line-height: 18px;
      border-color: transparent;
  }
}

@import '~@circlon/angular-tree-component/css/angular-tree-component.css';
